<template>
  <div>
    <CToaster :autohide="3000">
      <template v-for="(toast, index) in toasts">
        <CToast
          :key="'toast' + index"
          :show="true"
          :header="toast.title"
        >
          {{toast.message}}
        </CToast>
      </template>
    </CToaster>
    <CModal
      title="Editar Cuadrante"
      size="lg"
      color="primary"
      :show.sync="showModal"
    >
      <CCard v-if="cuadrante"
        style="max-height: 70vh;">
        <CCardHeader>
          <b>{{cuadrante.nombre}}</b>
        </CCardHeader>
        <CCardBody>
          <CRow class="d-flex justify-content-center align-items-center">
            <CCol lg="12" class="c-bg-success c-py-3">
              <CForm style="overflow-y: visible;">
                <CInput
                  placeholder="Nombre"
                  v-model='cuadranteUpdate.nombre'
                >
                  <template #prepend-content><CIcon name="cil-user"/></template>
                </CInput>
                <CInput
                  placeholder="Descripción"
                  v-model='cuadranteUpdate.descripcion'
                >
                  <template #prepend-content><font-awesome-icon icon="info-circle" /></template>
                </CInput>
                <CInput
                  placeholder="Color"
                  v-model='cuadranteUpdate.color'
                >
                  <template #prepend-content><font-awesome-icon icon="palette" /></template>
                </CInput>
                <CInput
                  placeholder="Url Socket"
                  v-model='cuadranteUpdate.socket_url'
                >
                  <template #prepend-content><font-awesome-icon icon="link" /></template>
                </CInput>
                <v-select
                  class="my-3"
                  placeholder="Pais"
                  :clearable="false"
                  :options="paises"
                  label="name"
                  @input="selectPais"
                  :reduce="data => data.id"
                  v-model="cuadranteUpdate.pais_id"></v-select>
                <v-select
                  class="my-3"
                  placeholder="Región"
                  :clearable="false"
                  :options="regiones"
                  label="region"
                  :reduce="data => data.id"
                  v-model="cuadranteUpdate.region_id"></v-select>
                <multiselect
                  v-if="comunas && cuadranteUpdate.pais_id === 81"
                  v-model="cuadranteUpdate.comuna_id"
                  :multiple="true"
                  placeholder="Busque y seleccione una o varias comunas"
                  :options="comunas"
                  :close-on-select="false"
                  label="comuna"
                  track-by="id">
                </multiselect>
              </CForm>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    	<template #footer>
	      <CButton
	        color="primary"
	        size="sm"
	        class="m-1"
	        @click="closeModal"
	      >
	        Cancelar
	      </CButton>
	      <CButton
	        color="success"
	        class="m-1"
	        @click="updateCuadrante()"
	      >
	        Actualizar
	      </CButton>
    	</template>
    </CModal>
  </div>
</template>

<script>
import { updateCuadrante, getRegionesByPais } from '../../api/cuadrantes'
import Multiselect from 'vue-multiselect'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

export default {
  name: 'EditCuadrante',
  props: ['showModal', 'cuadrante', 'closeModal', 'comunas', 'paises'],
  components: { Multiselect, 'v-select': vSelect },
  data: () => {
    return {
    	cuadranteUpdate: {
    		id: '',
    		nombre: '',
    		descripcion: '',
    		color: '',
    		comuna_id: [],
        socket_url: '',
        pais_id: null,
        region_id: null
    	},
      comunasID: [],
      toasts: [],
      regiones: []
    }
  },
  watch:{
  	cuadrante: function(){
  		if(this.cuadrante){
		  	this.cuadranteUpdate = {
          id: this.cuadrante.id,
          nombre: this.cuadrante.nombre,
          descripcion: this.cuadrante.descripcion,
          color: this.cuadrante.color,
          pais_id: this.cuadrante.pais_id,
          region_id: this.cuadrante.region_id,
          comuna_id: this.cuadrante.comuna_id,
          socket_url: this.cuadrante.socket_url,
		  	}
		  }
  	}
  },
  methods: {
    selectPais(data){
      console.log("selectPais: ", data)
      this.getRegion(data)
    },
    getRegion(pais_id){
      const self = this
      getRegionesByPais({pais_id})
        .then(function(resp){
          self.regiones = resp.regiones
        })
        .catch(function(error){
          console.log(error)
        })
    },
    updateCuadrante(){
      const self = this
      updateCuadrante(this.cuadranteUpdate)
        .then(function(resp){
          self.closeModal()
        })
        .catch(function(error){
          console.log(error)
        })
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>